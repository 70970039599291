.renewal {
  .header {
    background-color: hsl(0 0% 100% / 0.8);
    backdrop-filter: blur(16px);
  }

  .menu_wrap {
    .menu_item {
      &,
      > a,
      button {
        color: var(--black-color);
      }
    }
  }
  .menu_icon {
    .menu_icon-bar {
      span {
        background-color: var(--black-color);
      }
    }
  }
}

.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 20px;
  backdrop-filter: blur(16px);
  z-index: map-get($z-index, header);
  text-transform: uppercase;
  button {
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    overflow: visible;
    text-transform: uppercase;
    cursor: pointer;
  }
  @media screen and (max-width: $tablet) {
    height: 60px;
    padding: 0 16px;
  }
  .headerWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
  }
}

.logo {
  z-index: map-get($z-index, navContent);
  img {
    height: 32px;
  }

  @media screen and (max-width: $tablet) {
    img {
      height: 24px;
    }
  }
}

.desktop_nav {
  height: 100%;
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.menu_wrap {
  position: relative;
  display: flex;
  gap: 20px;
  height: 100%;
  .menu_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 16px;
    a,
    button {
      opacity: 0.5;
      &:hover,
      &.active {
        opacity: 1;
      }
    }

    &,
    > a,
    button {
      font-size: 15px;
      font-weight: 700;
      color: var(--menu-text);
      text-align: center;
      // transition: opacity 200ms;
    }
    a {
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        margin-left: 8px;
      }
    }
  }
}

.dropdown {
  display: flex;
  gap: 4px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    > i {
      transform: rotate(180deg);
    }
    .dropdown_wrap {
      display: grid;
    }
  }
}

.dropdown_wrap {
  position: absolute;
  display: grid;
  top: 70px;
  right: 0;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 30px hsl(230deg 4% 8% / 16%);
  padding: 12px 8px;
  z-index: map-get($z-index, nav);
  display: none;
  .menu_item {
    justify-content: flex-start;
  }
}

.menu_icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: map-get($z-index, nav);
  @media screen and (min-width: $tablet) {
    display: none;
  }
  @media screen and (max-width: $tablet) {
    width: 24px;
    height: 24px;
  }

  .menu_icon-cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
    z-index: map-get($z-index, navContent);
  }
  .menu_icon-bar {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 22px;
    height: 12px;
    z-index: map-get($z-index, nav);
    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      // background-color: var(--black-color);
      background-color: var(--menu-text);
      border-radius: 1px;
      transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

      &:first-of-type {
        top: 0;
      }
      &:last-of-type {
        bottom: 0;
      }
    }
  }
  .menu_icon-cheeckbox:checked + div {
    span {
      background-color: var(--black-color);
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }
}

// hide menu open
.menu_icon-cheeckbox:checked ~ .open_nav_container {
  display: block;
}

.menu_icon-cheeckbox:checked ~ .open_nav_container .open_menu_item {
  display: flex;
  animation-name: bottomFadeIn;
  animation-duration: 1s;
  opacity: 1;
}

.open_menu_item:nth-child(1) {
  animation-delay: -0.2s;
}
.open_menu_item:nth-child(2) {
  animation-delay: -0.1s;
}
.open_menu_item:nth-child(3) {
  animation-delay: -0s;
}

@keyframes bottomFadeIn {
  0% {
    bottom: -10%;
    opacity: 0;
  }

  100% {
    bottom: 0%;
    opacity: 1;
  }
}

// open menu
.open_nav_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  display: none;
}

.open_nav {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.open_menu_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 16%;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  padding: 0 20px;
  gap: 20px;

  .open_menu_item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    display: none;
    flex-direction: column;

    &,
    a,
    button {
      display: block;
      font-size: 64px;
      font-weight: 700;
      text-align: left;
      line-height: 1.5;
      color: var(--black-color);
      opacity: 0.5;
      @media screen and (max-width: $tablet) {
        font-size: 32px;
      }
      @media screen and (max-width: $moblie) {
        font-size: 28px;
      }
    }
    &.active > a,
    &.active > button,
    &:hover > a,
    &:hover > button {
      opacity: 1;
    }
    a {
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-left: 8px;
      }
    }
  }
}

.open_menu_item.dropdown_mobile {
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.5;
    i {
      display: none;
    }
  }
  .dropdown_wrap_mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 12px;
  }
  .open_menu_item a {
    font-size: 20px;
  }
  &.active,
  &:hover {
    > div {
      opacity: 1;
    }
  }
}
