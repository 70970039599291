.footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  justify-content: center;
  background-color: var(--dark-background);
  text-align: center;
  padding: 60px 20px;
  @media screen and (max-width: $tablet) {
    padding: 40px 20px;
  }
  @media screen and (max-width: $moblie-sm) {
    padding: 30px 20px;
  }
  span {
    display: block;
    color: var(--gray200-color);
    margin-bottom: 30px;
  }
  a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    opacity: 0.5;
    transition: opacity 200ms linear;
    &:hover {
      opacity: 1;
    }
  }
}

.footerSnsWrap {
  display: flex;
  gap: 16px;
}

.footerSns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 48px;
  background-color: hsl(0, 0%, 100%);
  img {
    width: 20px;
    filter: invert(1);
  }
}

.footerMenu {
  display: flex;
  gap: 34px;
}
