$moblie-sm: 360px;
$moblie: 500px;
$tablet: 1023px;

:root {
  --font-family-monospace: Pretendard, monospace;
  --font-family-highlight: Raleway, sans-serif;
  --header-height: 80px;
  --banner-height: 150px;
  --max-width: 1240px;
  --primary-color: #00b1ff;
  --menu-text: hsl(0, 0%, 100%);
  --background-color: hsl(220, 89%, 11%);
  --dark-background: #0d1b26;
  --blue100-color: #e0f4ff;
  --blue200-color: #003192;
  --black-color: #101214;
  --gray100-color: rgba(255, 255, 255, 0.8);
  --gray200-color: #828689;
  --border: #eff2f6;
  --black100-color: #40464f;

  // --gray300-color: #1b1f22;
  @media screen and (max-width: $tablet) {
    --header-height: 60px;
    --banner-height: 120px;
  }
}

//z-index
$z-index: (
  bg: 1,
  content: 2,
  header: 3,
  nav: 4,
  navContent: 5,
  modalBg: 6,
  modal: 7,
);
