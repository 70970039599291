.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.floatingMenu {
  z-index: map-get($z-index, nav);
  position: absolute;
  top: calc((100vh / 2) - 92px);
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  @media screen and (max-width: $moblie) {
    gap: 12px;
  }
  .floatingItem {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-color: hsl(0deg 0% 100% / 0.8);
      backdrop-filter: blur(16px);
    }
  }
}

.content {
  position: relative;
  width: 100%;
  margin-top: 80px;
  @media screen and (max-width: $tablet) {
    margin-top: 60px;
  }
}

.contentWrap {
  position: relative;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 180px 0;
  @media screen and (max-width: $tablet) {
    padding: 80px 20px;
  }
}

.sectionTitle {
  margin-bottom: 4%;
  text-transform: uppercase;
  font-family: var(--font-family-highlight);
  font-size: 64px;
  line-height: 1.2;
  font-weight: 800;
  @media screen and (max-width: $tablet) {
    font-size: 38px;
  }
  span {
    display: inline-block;
    color: var(--primary-color);
  }
}

// welcome

.welcome {
  position: relative;
  display: flex;
  align-items: center;
  height: var(--banner-height);
  background: linear-gradient(89.95deg, #3b38a4 0%, #00aaf5 50%, #3b38a4 100%);
  background-image: url("../../assets/images/banner/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  .welcomeTitle {
    display: grid;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    font-family: var(--font-family-highlight);
    justify-items: center;

    @media screen and (max-width: $moblie) {
      background-image: unset;
    }
    h1 {
      display: flex;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.2;
      font-weight: 700;
      @media screen and (max-width: $tablet) {
        padding: 0 40px;
        font-size: 14px;
      }
    }
    p {
      margin-top: 4px;
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      opacity: 0.8;
      @media screen and (max-width: $tablet) {
        font-size: 12px;
      }
    }
    span {
      display: inline-block;
      &.primary {
        font-family: var(--font-family-highlight);
        font-weight: 900;
        color: rgba(255, 255, 255, 0.64);
      }
    }
    .welcome_img_wrap {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto 1fr;
      margin-bottom: 14px;
      gap: 12px;
      @media screen and (max-width: $tablet) {
        gap: 8px;

        .arrow_wrap {
          i {
            font-size: 16px;
          }
          i:first-child {
            margin-right: -10px !important;
          }

          i:last-child {
            margin-left: -10px !important;
          }
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        padding: 2px 8px;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.12);
        font-family: var(--font-family-monospace);
        font-size: 10px;
        font-weight: 400;
        color: #fff;
      }
      img {
        width: auto;
        height: 32px;
        @media screen and (max-width: $tablet) {
          height: 24px;
        }
        &:first-of-type {
          margin-left: auto;
        }
      }
      .token_img {
        position: absolute;
        width: auto;
        height: 100px;
        &:first-child {
          top: 0;
          left: 0;
          background-color: #fff;
        }
      }
      .arrow_wrap {
        display: flex;
        align-items: center;
        i:first-child {
          margin-right: -16px;
          opacity: 0.2;
        }
        i:nth-child(2) {
          opacity: 0.6;
        }
        i:last-child {
          margin-left: -16px;
        }
      }
    }
  }
}
// .welcome {
//   position: relative;
//   display: flex;
//   align-items: center;
//   height: var(--banner-height);
//   background: hsl(242 49% 51%);
//   background-image: url("../../images/banner/bg.png");
//   background-size: auto 100%;
//   background-position: center;
//   background-repeat: no-repeat;
//   color: #fff;

//   .welcomeTitle {
//     display: grid;
//     justify-items: center;
//     width: 100%;
//     text-align: center;
//     font-family: var(--font-family-highlight);
//     padding: 0 20px;
//     h1 {
//       display: flex;
//       font-size: 20px;
//       font-weight: 700;
//       line-height: 1.2;
//     }
//     p {
//       color: #fff;
//       font-size: 15px;
//       font-weight: 300;
//       margin-top: 4px;
//     }

//     @media screen and (max-width: $tablet) {
//       h1 {
//         font-size: 14px;
//         padding: 0 40px;
//       }
//       p {
//         font-size: 12px;
//       }
//     }
//     @media screen and (max-width: $moblie) {
//       background-image: unset;
//     }
//     span {
//       display: inline-block;
//       &.primary {
//         font-weight: 900;
//         font-family: var(--font-family-highlight);
//         color: rgba(255, 255, 255, 0.64);
//       }
//     }
//     .welcome_img_wrap {
//       display: grid;
//       grid-template-columns: repeat(5, auto);
//       align-items: center;
//       gap: 12px;
//       margin-top: 14px;
//       span {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         padding: 2px 8px;
//         height: fit-content;
//         border-radius: 20px;
//         font-size: 10px;
//         font-weight: 400;
//         color: #fff;
//         background: rgba(0, 0, 0, 0.12);
//         font-family: var(--font-family-monospace);
//       }
//       img {
//         width: auto;
//         height: 40px;
//         &:first-of-type {
//           height: 28px;
//         }
//       }
//       .token_img {
//         position: absolute;
//         width: auto;
//         height: 100px;
//         &:first-child {
//           top: 0;
//           left: 0;
//           background-color: #fff;
//         }
//       }
//       .arrow_wrap {
//         display: flex;
//         align-items: center;
//         i:first-child {
//           margin-right: -16px;
//           opacity: 0.2;
//         }
//         i:nth-child(2) {
//           opacity: 0.6;
//         }
//         i:last-child {
//           margin-left: -16px;
//         }
//       }
//       @media screen and (max-width: $tablet) {
//         gap: 8px;
//         margin-top: 10px;
//         img {
//           width: auto;
//           height: 24px;
//           &:first-of-type {
//             height: 20px;
//           }
//         }
//         .arrow_wrap {
//           i {
//             font-size: 16px;
//           }
//           i:first-child {
//             margin-right: -10px;
//           }

//           i:last-child {
//             margin-left: -10px;
//           }
//         }
//       }
//     }
//   }
// }

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// vision
.vision {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: var(--dark-background);
  color: #fff;
}

.vision_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  padding-left: 20px;
  gap: 16vw;
  @media screen and (max-width: $tablet) {
    display: grid;
    gap: inherit;
  }
}

.moveToXpla {
  position: absolute;
  right: 20px;
  bottom: 40px;
  width: 120px;
  height: 120px;
  font-family: var(--font-family-highlight);
  @media screen and (max-width: $tablet) {
    position: relative;
    top: 0;
    margin-left: auto;
    transform: translateX(20px);
  }
  &:hover {
    svg {
      animation-play-state: paused;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    font-weight: 300;
    animation: rotate 15s linear infinite;
    transform-origin: center;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  color: #fff;
  transform: translate(-50%, -40%);
  justify-items: center;
  img {
    height: 22px;
  }
  i {
    font-variation-settings: "wght" 200;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

.vision_header {
  z-index: 1;
  display: grid;
  max-width: 558px;
  gap: 20px;
  @media screen and (max-width: $moblie) {
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 14px;
    }
  }

  h1 {
    text-transform: uppercase;
    font-family: var(--font-family-highlight);
    font-size: 64px;
    line-height: 1.2;
    font-weight: 800;
    em {
      opacity: 0.5;
    }
  }
  p {
    max-width: 400px;
    font-size: 16px;
    font-weight: 300;
    opacity: 0.7;
  }
}

.vision_video {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  height: 580px;
  overflow: hidden;
  border-radius: 400px;
  transform: translate(-50%, -50%);
  @media screen and (max-width: $tablet) {
    top: 240px;
    width: 320px;
    height: auto;
    transform: translate(-50%, 0);
    aspect-ratio: 3 / 5;
    // left: inherit;
    // right: 20px;
    // transform: inherit;
  }
  @media screen and (max-width: $moblie) {
    top: 260px;
    width: 217px;
  }

  video {
    height: 100%;
  }
}

.vision_main {
  z-index: 1;
  // flex: 2;
  max-width: 412px;
  @media screen and (max-width: $tablet) {
    max-width: 100%;
    margin-top: 400px;
    margin-bottom: 40px;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    .vision_item {
      img {
        display: none;
      }
    }
  }
  @media screen and (max-width: $moblie) {
    margin-top: 370px;
  }
  @media screen and (max-width: $moblie-sm) {
    margin-top: 340px;
  }

  ul {
    display: grid;
    gap: 60px;
  }
  .vision_item {
    display: grid;
    gap: 12px;
    @media screen and (max-width: $moblie) {
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
    }
    &:hover {
      img {
        opacity: 0.2;
      }
    }
    h1 {
      font-size: 36px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      opacity: 0.7;
    }
    img {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 360px;
      width: 100%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 200ms linear;
    }
  }
}

// game

.game {
  position: relative;
  overflow: hidden;
  .contentWrap {
    position: relative;
    width: 100%;
    padding: 8% 20px;
    @media screen and (max-width: $moblie) {
      padding: 80px 20px;
    }
  }
  .titleWrap {
    p {
      color: var(--gray200-color);
    }
  }
}

.gameImgContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gameImgWrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 20px;

  img {
    position: relative;
    width: 22%;

    @media screen and (max-width: $tablet) {
      width: 18%;
      &:nth-child(1) {
        right: -44px;
      }
      &:nth-child(2) {
        right: -12px;
      }
    }
    @media screen and (max-width: $moblie) {
      display: none;
    }
    &:hover {
      z-index: 2;
      transform: scale(1.1) !important;
    }
    &:nth-child(1) {
      top: -5%;
      right: -68px;
    }
    &:nth-child(2) {
      top: 7%;
      right: -18px;
    }
    &:nth-child(3) {
      top: -12%;
    }
  }
}

.gameListContainer {
  position: relative;
}

.gameArrowWrap {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px 20px;
  gap: 20px;
  .gameArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 2px solid var(--primary-color);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.1s;
    &:not(.swiper-button-disabled):hover {
      background-color: var(--primary-color);
      img {
        filter: grayscale(1) contrast(800%);
      }
    }
    &.swiper-button-disabled {
      filter: grayscale(1) brightness(140%);
    }

    &.left {
      transform: rotate(-180deg);
    }
  }
}

.gameListWrap {
  display: flex;
  width: 100%;
  padding: 0 20px;
  flex: 1;
  gap: 20px;
}

.gameList {
  position: relative;
  &:hover {
    .gameListImg {
      img {
        transform: scale(1.1);
      }
    }
  }
  .gameListImg {
    overflow: hidden;
    img {
      display: flex;
      width: 100%;
      transition: transform 0.5s;
    }
  }
}

.gameBottomWrap {
  display: flex;
  flex-direction: column;
  height: 130px;
  padding: 14px 16px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
}

.gameTitle {
  margin-top: 4px;
  margin-right: auto;
  margin-right: 4px;
  margin-bottom: 18px;
  line-height: 24px;
  font-weight: 700;
  color: var(--black-color);
}

.gameInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.gameInfoItem {
  display: flex;
  flex-direction: column;
  gap: 2px;
  .gameInfoTitle {
    color: var(--gray200-color);
  }
  .gameInfoSub {
    margin-bottom: 20px;
    font-weight: 500;
    color: var(--black-color);
  }
}

.gameStoreBtnWrap {
  display: flex;
  flex: 1;
  gap: 8px;
  > .gameStoreBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f8f8f8;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--black-color);
    transition: all 0.2s;
    flex: 5;
    &:not(.comingSoon):not(.disable):hover {
      opacity: 0.65;
    }
    &.homepage {
      width: 40px;
      border: 1px solid #a6a6a6;
      background-color: #000;
      flex: 1;
      img {
        width: 24px;
        margin-right: 0;
      }
    }
    &.play {
      border: 1px solid #a6a6a6;
      background-color: #000;
    }
    &.comingSoon {
      color: var(--gray200-color);
    }
    &.disable {
      pointer-events: none;
      opacity: 0.3;
    }
    img {
      width: 100%;
      height: 100%;
    }
    span {
      white-space: nowrap;
      line-height: 24px;
    }
  }
}

.gameLabelWrap {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}
.gameLabel {
  display: block;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 700;
  color: #fff;
  &.live {
    background-color: #63e100;
  }
  &.preSale {
    background-color: #fd3c81;
  }
  &.beta {
    background-color: #ff6006;
  }
  &.event {
    background-color: var(--primary-color);
  }
}

// partners
.partners {
  text-align: center;
}
.partnerListWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
  gap: 40px;
  @media screen and (max-width: $tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: $moblie) {
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    img {
      width: 70%;
    }
  }
  .partnerList {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 200px;
    }
  }
}

//exchages
.exchanges {
  text-align: center;
  .contentWrap {
    position: relative;
    width: 100%;
    padding-top: 0;
  }
  .partnerList {
    a {
      transition: 200ms;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

// coming soon
section.comingSoon {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--banner-height) - 80px);
  overflow: hidden;
  gap: 60px;
  @media screen and (max-width: $tablet) {
    min-height: calc(100vh - var(--banner-height) - 60px);
  }
  header {
    display: grid;
    padding: 0 20px;
    text-align: center;
    font-family: var(--font-family-highlight);
    gap: 20px;
    @media screen and (max-width: $tablet) {
      h1 {
        font-size: 64px;
      }
      h2 {
        font-size: 18px;
      }
    }
    @media screen and (max-width: $moblie) {
      h2 {
        em {
          display: block;
        }
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 78px;
      line-height: 1;
      font-weight: 900;
      color: var(--menu-text);
      em {
        opacity: 0.6;
      }
    }
    h2 {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 500;
      color: var(--menu-text);
      span {
        background: linear-gradient(-45deg, #00b1ff, #f59dc3, #c794e9);
        background-size: 400% 400%;
        background-clip: text;
        -webkit-background-clip: text;
        font-family: var(--font-family-highlight);
        font-weight: 900;
        color: var(--menu-text);
        animation: AnimationName 8s linear infinite;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.countDown {
  ul {
    display: flex;
    li {
      position: relative;
      display: grid;
      padding: 0 16px;
      text-align: center;
      font-family: var(--font-family-monospace);
      color: var(--menu-text);
      row-gap: 8px;
      @media screen and (max-width: $tablet) {
        h1 {
          font-size: 38px;
        }
      }
      &:not(:last-child):after {
        content: ":";
        position: absolute;
        top: 50%;
        right: 0;
        color: var(--menu-text);
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
      h1 {
        font-size: 60px;
        line-height: 1;
        font-weight: 800;
      }
    }
  }
}

.videoBg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
