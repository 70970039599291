.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px 80px;
  h5 {
    text-transform: uppercase;
    margin-top: 2%;
    margin-bottom: 20px;
    span {
      color: var(--primary-color);
    }
  }
  p {
    color: var(--gray200-color);
  }
  a {
    margin-top: 40px;
    display: block;
    padding: 16px 20px;
    border: 1px solid var(--black-color);
    color: var(--black-color);
  }
}
