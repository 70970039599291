.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 630px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: map-get($z-index, modal);
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.28);
  @media screen and (max-width: $tablet) {
    width: 60%;
  }
  @media screen and (max-width: $moblie) {
    width: 90%;
  }
}

.modalDialog {
  display: block;
  width: 100%;
  max-height: 580px;
  overflow: hidden;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 20px 40px;
  background: linear-gradient(269deg, #002f96 -12%, #00b1ff 70%);
  .modalLogo {
    height: 36px;
  }
  .modalClose {
    height: 24px;
  }
  @media screen and (max-width: $moblie) {
    padding: 20px 30px;
    .modalLogo {
      height: 28px;
    }
    .modalClose {
      height: 20px;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 48px 40px;
  max-height: 500px;
  overflow-y: auto;
  p {
    color: var(--gray200-color);
  }
  @media screen and (max-width: $moblie) {
    padding: 40px 30px;
  }

  @media screen and (max-width: $moblie-sm) {
    height: 520px;
  }
}

.modalDontShow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  z-index: map-get($z-index, modal);
}

.modalBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: map-get($z-index, modalBg);
}
