* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  font-weight: initial;
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: auto;
  // height: 100%;
}

body {
  font-family: Pretendard, sans-serif;
  color: var(--black-color);
  word-break: keep-all;
  line-height: 1.5;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
  &.renewal {
    background-color: #fff;
  }
}

.xl-txt {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.2;
  @media screen and (max-width: $tablet) {
    font-size: 40px;
  }
  @media screen and (max-width: $moblie) {
    font-size: 28px;
  }
}

.l-txt {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  @media screen and (max-width: $tablet) {
    font-size: 22px;
  }
}

.m-txt {
  max-width: 400px;
  font-size: 16px;
  font-weight: 300;
  @media screen and (max-width: $moblie) {
    font-size: 14px;
  }
}

.s-txt {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  @media screen and (max-width: $tablet) {
    font-size: 16px;
  }
}

.xs-txt {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  @media screen and (max-width: $tablet) {
    font-size: 13px;
  }
}

.xxs-txt {
  font-size: 14px;
  @media screen and (max-width: $tablet) {
    font-size: 12px;
  }
}
